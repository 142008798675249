import { Link } from 'gatsby';
import gsap from 'gsap/all';
import React, { Component, useRef, useEffect } from 'react'

const ProjectSmallBlockV4 = (props) => {


    const coverImg = useRef()
    // const tl = useRef();
    // tl.current = gsap.timeline();



        const { frontmatter } = props;

        if(props.story)
            return (
                <a
                href={frontmatter.url}
                className=" blur-sm relative bg-opacity-75 flex flex-col hover:bg-gray-800 transform scale-100 hover:scale-105  items-center  rounded-lg px-2 md:px-8 py-4 hover:py-16 group mx-4 mb-6 transition ease-in-out duration-500">
                   
                   <div className='w-full'>
                        <img src={frontmatter.cover} />
                    </div>
                   
                    <div className='w-full mt-12'>
                        <div className="flex items-start justify-start pb-4 ">
                            <img className="h-8 object-contain" src={frontmatter.img}/>
                        </div>
                        <div className="">
                            <span className="text-base md:text-2xl font-mediumstr">{frontmatter.title}</span>
                            <p className="text-sm md:text-lg font-normalstr mt-2 text-gray-400">{frontmatter.desc}</p>

                            <div className='text-blue-500 opacity-0 group-hover:opacity-100 duration-150 mt-2 font-mediumstr text-base'>Read case study</div>
                        </div>
        
                        <div className="">
                            {/* <div ref={coverImg} className="absolute right-0 top-0 w-64 hover:-top-10 duration-500 transform-gpu translate-y-2 group-hover:-translate-y-4 group-hover:scale-95 scale-100">
                                <img src={frontmatter.cover} />
                            </div> */}
                        </div>
                    </div>

                    
                    
                </a>
            )


        return (
            <a 
          
            href={frontmatter.url} target="_blank" className="bg-gray-900 blur-sm relative bg-opacity-75 hover:bg-gray-800 rounded-lg px-8 md:px-8 scale-100 transform hover:scale-105 py-5 group mx-4 mb-6 flex items-center transition ease-in-out duration-500">
                <div className="flex items-center justify-center w-16">
                    <img className="w-full" src={frontmatter.img}/>
                </div>
                <div className="ml-6">
                    <span className="text-base md:text-lg font-mediumstr">{frontmatter.name}</span>
                    <p className="opacity-75 text-sm md:text-base font-light">{frontmatter.desc}</p>
                </div>

                <div className="">
                    {/* <div ref={coverImg} className="absolute right-0 top-0 w-64 hover:-top-10 duration-500 transform-gpu translate-y-2 group-hover:-translate-y-4 group-hover:scale-95 scale-100">
                        <img src={frontmatter.cover} />
                    </div> */}
                </div>
                
            </a>
        )
    
        
    }


export default ProjectSmallBlockV4;