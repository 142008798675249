import React, { Fragment, useEffect, useState,useRef, Suspense } from "react"
import { Link } from "gatsby"

import Image from "../components/image"
import SEO from "../components/seo"
import TextEffects from "../components/TextEffects";
import Logo from "../components/Logo";
import gsap from "gsap";
import { graphql } from 'gatsby';
import Layout from "../components/layout";
import ProjectSmallBlockV4 from "../components/ProjectSmallBlockV4";

import { EffectComposer,  Noise } from '@react-three/postprocessing'
import { useSpring, a } from "@react-spring/three"
import {animated} from 'react-spring';
import { useDrag,useScroll, useWheel  } from "@use-gesture/react"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useMouse from '@react-hook/mouse-position'


import * as THREE from 'three'
import { Canvas, useFrame, useLoader, extend, useThree } from '@react-three/fiber';
import { GLTFLoader, Environment, useTexture, Loader, shaderMaterial, OrbitControls, Text, Icosahedron , ScrollControls} from '@react-three/drei';
//import DistortMaterial from "../components/FrontAnimation/DistrotionMaterial";
//import FrontAnimation from "../components/FrontAnimation";
var glsl = require('glslify')


function Box(props) {
  const mesh = useRef();
  
  useFrame((state, delta) => (mesh.current.rotation.x += 0.01) )

  return (
    <mesh
      {...props}
      ref={mesh}
    >
       <sphereGeometry args={[1, 1, 1]} />
       <meshStandardMaterial  color="hotpink" roughness={0.4} thickness={3.3}  />

    </mesh>
  )
}


function BGBackgroundMesh(props) {
  const texture = useTexture('./bg-ligh-copy.jpg')
  const mesh = useRef();

  return (
    <mesh {...props} position={[0,0,-1]} ref={mesh} scale={1.5}>
      <planeGeometry args={[13.66, 6.7, 5, 5]} />
      <meshBasicMaterial map={texture} />
    </mesh>
  )
}


const WaveShaderMaterial = shaderMaterial(
  // Uniform
  {
    uTime: 0,
    uColor: new THREE.Color(0.0, 0.0, 0.0),
    uTexture: new THREE.Texture(),
    lightIntensity: { value: new THREE.Vector4(0.5, 0.5, 0.5, 1.0) },
    lightPosition: { value: new THREE.Vector4(0.0, 2000.0, 0.0, 1.0) },
    Shininess: { value: 200.0 }
  },
  // Vertex Shader
  glsl`
    precision mediump float;
 
    varying vec2 vUv;
    varying float vWave;

    uniform float uTime;

    #pragma glslify: snoise3 = require(glsl-noise/classic/3d.glsl);

    void main() {
      vUv = uv;

      vec3 pos = position;
      float noiseFreq = 0.5;
      float noiseAmp = 0.1;
      vec3 noisePos = vec3(pos.x * noiseFreq + uTime, pos.y, pos.z);
      pos.z += snoise3(noisePos) * noiseAmp;
      vWave = pos.z;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);  
    }
  `,
  // Fragment Shader
  glsl`
    precision mediump float;

    uniform vec3 uColor;
    uniform float uTime;
    uniform sampler2D uTexture;

    varying vec2 vUv;
    varying float vWave;
    

    void main() {
      float wave = vWave * 0.5;

      float r = texture2D(uTexture, vUv + (wave*0.4)).r;
      float g = texture2D(uTexture, vUv).g;
      float b = texture2D(uTexture, vUv + wave).b;

      vec3 texture = vec3(r, g, b);
      gl_FragColor = vec4(texture, 1); 
    }
  `,
  false,
  true
);

extend({ WaveShaderMaterial })
const Wave = (props) => {
  const ref = useRef();
  const mesh = useRef();
  useFrame(({ clock }) => {


    //console.log(props.sphereLocation);
    //props.scrollMove
    const animationStrenght = props.startAnimationBG ? props.startAnimationBG : 0;
//props.startAnimationBG.x
    mesh.current.position.x = props.requiredPosition[0] - animationStrenght.x;
    mesh.current.position.y = (props.requiredPosition[1] - animationStrenght.y)  + (props.scrollMove ? props.scrollMove.y * 0.4 : 0 );
    mesh.current.position.z = props.requiredPosition[2] - animationStrenght.z;
    
    return(ref.current.uTime = clock.getElapsedTime()) 
  
  });

  const textureImages = useLoader(THREE.TextureLoader, [
    "https://res.cloudinary.com/enigmabuild/image/upload/v1643889533/bg-light-left_ukukgc.jpg",
    "https://res.cloudinary.com/enigmabuild/image/upload/v1643889533/bg-light-right_yo9k38.jpg",
    "https://images.unsplash.com/photo-1573655349936-de6bed86f839?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3120&q=80",
    "https://res.cloudinary.com/enigmabuild/image/upload/v1643892113/bg-light_copy_ytamqa.jpg",
    "https://res.cloudinary.com/enigmabuild/image/upload/v1643909498/bg-light_copy_wcyqch.jpg"

  ]);

  //[.60,0.61, 16, 16]
  return (
    <mesh ref={mesh} {...props}>
        <planeBufferGeometry args={[1.366, 0.670, 32, 32]} />
        <waveShaderMaterial ref={ref} uColor="white" uTexture={textureImages[props.imageType]}  />
    </mesh>
  )
}

// <sphereBufferGeometry  args={[1,64,64]} />
// extend({SphereShaderMaterial })
const SphereBox = ({startAnimationSphere, sphereLocation, mousePointer }) => {
  const ref = useRef();
  const mesh = useRef();


//   const { size, viewport } = useThree()
//   const aspect = size.width / viewport.width;
//   const [spring, set] = useSpring(() => ({ position: [0, 0, 0], config: { mass: 3, friction: 40, tension: 800 } }))

//   const bind = useDrag(({ movement: [x, y], down }) => {
//     return set({ immediate: down, position: down ? [x / aspect, -y / aspect, 0] : [0, 0, 0] }), {
//       eventOptions: { pointer: true }
//     }
// })
   
  //const scrollSituation = useScroll();




  useFrame((state, delta) => {
    //console.log(state.mouse);

    const requiredScale = 0.3;
    mesh.current.scale.x = startAnimationSphere.x * requiredScale;
    mesh.current.scale.y = startAnimationSphere.x * requiredScale;
    mesh.current.scale.z = startAnimationSphere.x * requiredScale;

    
    //console.log(scroll)
    mesh.current.rotation.x = Math.sin(state.clock.getElapsedTime())
    mesh.current.rotation.y += 0.007;


    const y = sphereLocation.y;
    const x =  0.2 + (state.mouse.x * 0.2)
    // mesh.current.position.y = sphereLocation.y 
    // mesh.current.position.x = 0.2 + (state.mouse.x);
    mesh.current.position.set(x, y, 0)
    //console.log(sphereLocation.y, mesh.current.position.y ) 

    return ;
  } )


  // const textures = useTexture(["/ao.jpg", "/normal.jpg", "/height.png", "/roughness.jpg"])

  const [aoMap, roughnessMap, normalMap, displacementMap] = useLoader(THREE.TextureLoader, ["/ao.jpg", "/roughness.jpg", "/normal.jpg", "/height.png"])

  return (
    <mesh ref={mesh} position={[0.2,0.1, 0.1]}>
       <octahedronBufferGeometry  args={[1.4, 1]} />
      <meshPhysicalMaterial 
      flatShading
        aoMap={aoMap}
        normalMap={normalMap}
        displacementMap={displacementMap}
        roughnessMap={roughnessMap}
        reflectivity={0.32}
        roughness={0.5} 
        thickness={10} 
        transmission={1} 
        clearcoat={0.3} 
        ior={1.16 } 
        sheen={0.3}  />
    </mesh>
  )
}





function ShaderPlane() {
  const ref = useRef()
  const { width, height } = useThree((state) => state.viewport)
  useFrame((state, delta) => (ref.current.time += delta))
  return (
    <mesh scale={[width, height, 1]}>
      <planeGeometry args={[1, 1, 16, 16]} />
      {/* We use the materials module 🔑 to allow HMR replace */}
      <trippyWaveMaterial ref={ref} colorStart="pink" colorEnd="white" />
    </mesh>
  )
}


      {/* <meshPhysicalMaterial  
        displacementMap={displacementMap}
        normalMap={normalMap}
        roughnessMap={roughnessMap}
        aoMap={aoMap} 
        envMapIntensity={50}
        roughness={0.1} thickness={5} transmission={0.9} clearcoat={0}  ior={1.2} transparent color="black"  /> */}

const TextBG = () => {
  return (
    <mesh>
      <Text color="white" anchorX="center" anchorY="middle">
          enigma
        </Text>
    </mesh>
  )
}










const Scene = ({startAnimationSphere, startAnimationBG, sphereLocation, mousePointer}) => {


  return ( 
  
    <Canvas camera={{ fov: 12, position: [0.06, 0, 4.4] }}>
      <ambientLight />
    {/* <pointLight castShadow intensity={1} position={[1,1, 8]} /> */}
      <Suspense fallback={null}>
        <Wave startAnimationBG={startAnimationBG} scrollMove={sphereLocation} imageType={4} scale={1.4} requiredPosition={[0, 0.1, 0]} />
        {/* <Wave imageType={2} scale={2} position={[0.6, 0.0, 0.4]} /> */}
        <SphereBox mousePointer={mousePointer} startAnimationSphere={startAnimationSphere} sphereLocation={sphereLocation}  />
        <Wave imageType={4} scale={1.4} requiredPosition={[0, 0.1, 4.5]} />
 
      </Suspense>
{/* 
      <EffectComposer>
        <Noise opacity={0.01} />
      </EffectComposer> */}

    </Canvas>

  )
}


const IndexPage = ({ data }) => {

  // const [logoRef,setLogoRef] = useState(null);
  // const [h1Ref,seth1Ref] = useState(null);
  // const [h2Ref,seth2Ref] = useState(null);
  const [contentRef, setContentRef] = useState(null);

  const logoRef = useRef(null);
  const h1Ref = useRef(null)
  const h2Ref = useRef(null)
  const ctaRef = useRef(null);
  const tl = useRef(null);
  const tl2 = useRef(null);
  const scrollMoveSphere = useRef();
  const mainRef = useRef(null);
  const changeBackgroundRef = useRef();

  //const [contentSetRef,setContentSetRef] = useState([]);

  let collabListRef = useRef();
  collabListRef.current = []

  let startAnimationSphere = {
    x: 0.01,
    y:0.01
  }

  let startAnimationBG = {
    x: 0.01,
    y:0.01
  }

  let sphereLocation = {
    x: 0,
    y:0
  }

  useEffect(() => {

    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);
  }




  scrollMoveSphere.current = gsap.timeline({
    paused: true,
    scrollTrigger: {
        trigger: mainRef.current[0],
        scrub: 1.7,
        end: "+=2000",
    }
});
scrollMoveSphere.current.set(sphereLocation, {
  x:0,
  y:0,

  
})
scrollMoveSphere.current.to(sphereLocation, {
   x:0,
   y:0.6,
    ease: 'back.out(1.7)',
   
})




changeBackgroundRef.current = gsap.timeline({
  paused: true,
  scrollTrigger: {
      trigger: contentRef,
      scrub: 0.5,
      start: "-=400",
      end: "+=200",

  }
});


changeBackgroundRef.current.set(contentRef, {
  backgroundColor: 'rgba(0,0,0,0)',
  opacity:0.6,
  scale:0.9
})

changeBackgroundRef.current.to(contentRef, {
  backgroundColor: 'rgba(25,25,25,1)',
  opacity:1,
  scale:1
})

    tl2.current = gsap.timeline();
    tl2.current.fromTo(startAnimationBG, {
      x: 0,
      y:-0.5,
      z:0
    },  {
      x:0,
      y:0,
      z:0,
      duration:1, ease: "power3.inOut",
    })
    tl2.current.fromTo(startAnimationSphere, {
      x: 0.1,
      y:0.1
    },  {
      x:1,
      y:1,
      duration:2.5, ease: "power3.inOut",
    })
    
    tl.current = gsap.timeline();
   
    tl.current.fromTo(logoRef.current, { y: 50, opacity:0, }, { y:0, opacity:1,  duration:2, ease: "expo.inOut" }, );
    tl.current.fromTo(h1Ref.current, { y: 50, opacity:0 }, { y:0, opacity:1, ease: "power3.inOut"});
    tl.current.fromTo(h2Ref.current, { y: 50, opacity:0 }, { y:0, opacity:0.8, ease: " power3.inOut"});
    tl.current.fromTo(ctaRef.current, { y: 50, opacity:0 }, { y:0, opacity:0.8, ease: " power3.inOut"});

    
    tl.current.fromTo(contentRef, { y: 50, opacity:0,  scale:0.8 }, { y:0, opacity:0.6, scale:0.9, ease: " expo.inOut", duration:0.1});
    



    tl.current.to(collabListRef.current, {
      duration: 1.5,
      ease: 'Expo.easeOut',
      startAt: {y: '100%', opacity:0},
      y: 0,
      opacity: 100,
      rotation:0,
      delay: pos => pos*0.06
    });



   



    //tl.restart();
  }); 


  


  const mousePointer = {
    x:0,
    y:0
  }


  let mouseHoverRef = useRef({
    x:1
  });

  useEffect(() => {
    if(collabListRef && collabListRef.current) {
     collabListRef.current.forEach((link) => {
       link.addEventListener("mouseenter", () => {
          gsap.to(mouseHoverRef.current, {
              x:2.5
          })
       });


       link.addEventListener("mouseleave", () => {
        gsap.to(mouseHoverRef.current, {
            x:1
        })
     });



     });
    }
    return () => {
      if(collabListRef && collabListRef.current) {
        collabListRef.current.forEach((link) => {

          link.removeEventListener("mouseleave")

          link.removeEventListener("mouseenter")
        });
       }
    };
  }, [collabListRef]);
  

  


  return (
  <Layout mouseHoverState={mouseHoverRef.current}  >
      <SEO title="Home" />

      <div ref={mainRef} className=" pb-12 pt-24 relative" >
        <div className="absolute w-screen h-screen top-0 left-0">
          {/* <Canvas frameloop="demand" >
            <spotLight intensity={0.5} angle={0.2} penumbra={1} position={[5, 15, 10]} />
            <Suspense fallback={null}>
              <Box></Box>
              <BGBackgroundMesh />
             
            </Suspense>
           
          </Canvas> */}
          <Scene mousePointer={mousePointer} sphereLocation={sphereLocation} startAnimationSphere={startAnimationSphere} startAnimationBG={startAnimationBG} />


          {/* <FrontAnimation /> */}
        </div>


        <div className="max-w-screen-lg mx-auto text-left md:pt-32 px-12">
          {/* <div className="text-white flex items-center justify-start">
            <img src={ require('../images/logo-v2-dark.svg') }></img>
            <span className="text-4xl font-semibold ml-3">enigma</span>
          </div> */}
          <div ref={logoRef} >
            <Logo />
          </div>
          <div className="mt-16 max-w-screen-lg mx-auto">
            <h1 ref={ h1Ref } className="text-white text-2xl md:text-3xl font-mediumstr tracking-tight">Grow your business with functionally beautiful software ❤️</h1>
            <h2 ref={ h2Ref } className=" text-xl  md:text-xl font-normalstr mt-3 md:mt-2 text-gray-100">Your on-demand <span className="">design partner</span> to help you build great software experience</h2>
            <a ref={ ctaRef } href="/contact" className={`bg-gray-800 inline-block rounded-md hover:bg-gray-600 text-white text-base font-mediumstr px-8 py-4 mt-6 duration-150`}>Schedule a call</a>
            {/* <TextEffects /> */}
          </div>
        </div>
      </div>


      


      <div className="py-32" ref={ ref => setContentRef(ref) }>

{/* 
      <div className="max-w-5xl px-4 mx-auto">
        <div className="grid grid-cols-3 gap-4">
          <div className="px-2 py-3 rounded-lg bg-gray-900 bg-opacity-50">
              <h2 className="text-white">Improve your MRR</h2>
              <p></p>
          </div>

          <div className="px-2 py-3 rounded-lg bg-gray-900 bg-opacity-50">
              <h2>Increase Customer Satisfaction</h2>
              <p></p>
          </div>

          <div className="px-2 py-3 rounded-lg bg-gray-900 bg-opacity-50">
              <h2>Improve your MRR</h2>
              <p></p>
          </div>
        </div>
      </div> */}


        { /* <div className="mt-16 max-w-screen-lg mx-auto text-white">
            <small className="text-base font-semibold text-white opacity-50 px-12 mb-4 block">Products</small>
            <div className="grid grid-cols-2 px-8">
              { data.allMarkdownRemark.edges.filter(a => a.node.frontmatter.type == 0).map(project => <ProjectSmallBlockV4 {...project.node} /> ) }
          </div>
        </div> */ }

      <div className="mt-16 max-w-6xl mx-auto text-white">
          {/* <small className="text-base font-semibold text-white opacity-50 px-12 mb-4 block">Collaborations</small> */}

          <div className="grid grid-cols-1 px-8">
          { data.allMarkdownRemark.edges.filter(a => a.node.frontmatter.type == 3).map((project,index) => 
              <div className="opacity-0 mb-24" style={{ transform:'rotate(5deg)' }} ref={ r => collabListRef.current[index] = r }>
                <ProjectSmallBlockV4 story key={index}  {...project.node} />
              </div> 
          )}
          </div>
          <div className="grid md:grid-cols-2 grid-cols-1 px-8">


          { data.allMarkdownRemark.edges.filter(a => a.node.frontmatter.type == 1).map((project,index) => 
              <div className="opacity-0 transform" style={{ transform:'rotate(5deg)' }} ref={ r => collabListRef.current[index+2] = r }>
                <ProjectSmallBlockV4 key={index}  {...project.node} />
              </div> 
          )}
          </div>
      </div>

      
      <div className='mt-24 max-w-screen-lg mx-auto text-white px-12'>
          <h1 className="text-white text-xl md:text-4xl font-bold">Handful of project per year.</h1>
            <p className="text-base md:mr-24 mt-4 opacity-75">Enigma was started on 2017 with a vision of building products that helps companies ship products faster. We only commit to handful of projects per year. We work directly with founders to redefine the roadmap and together design the best solution possible.</p>
      </div>


      {/* <div className='mt-24 max-w-screen-lg mx-auto text-white px-12'>
          <h1 className="text-white text-xl md:text-4xl font-bold">Love to work together?</h1>
          <h2 className="text-white text-xl md:text-4xl font-bold opacity-50 block">Drop us an email</h2>
          <a href="mailto:athul@madebyenigma.com" className="bg-white text-black py-3 px-4 font-semibold rounded-lg mt-4 inline-block cursor-pointer">Get in touch →</a>
      </div>


      <div className='mt-16 max-w-screen-lg mx-auto px-12 '>
        <div className="flex justify-between items-center border-t border-gray-800 py-16 ">
          <div className="address text-white">
            <h6 className="opacity-75 mb-2">Enigma Software Solutions Private Limited</h6>
            <p className="opacity-50 text-sm"> Padmavathi Industrial,<br />Koramangala <br /> Bangalore KA 560034</p>
            <p className="opacity-50 text-sm mt-4">Drop a note at athul @ madebyenigma.com</p>

          </div>
          <div>
            <Logo logo className="w-10" />
          </div>
        </div>
      </div> */}

        
      </div>

    </Layout>
  )
}
  


export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }

    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            desc
            name
            title
            url
            img
            type
            cover
            title
          }
        }
      }
    }
  }
`




export default IndexPage
