import React, { Component } from 'react'
import gsap from 'gsap'

export default class TextEffects extends Component {

    
    constructor(props) {
        super(props);
        this.textRef = null;
    }
    componentDidMount() {
      
    }
    render() {
        return (
            <div ref={ ref => this.textRef }>
                Hello there
            </div>
        )
    }
}
